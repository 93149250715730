import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLetterboxd } from '@fortawesome/free-brands-svg-icons';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { getError } from '../../Utils';
import { Link } from 'react-router-dom';
import { Store } from '../../Store'; // Import the global store

function TopCategories() {
  const appBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [categories, setCategories] = useState([]);
  const { dispatch } = useContext(Store); // Get dispatch from the context

  const homeCategories = async () => {
    try {
      const { data } = await Axios.get(`${appBaseUrl}/api/categories/home`, {
        params: { isHome: true },
      });
      setCategories(data);
      dispatch({ type: 'SET_CATEGORIES', payload: data });
    } catch (err) {
      toast.error(getError(err));
    }
  };

  useEffect(() => {
    // const allCategories = [
    //   'Purana Zamana',
    //   'Antarrashtriy kissay',
    //   'Parenting',
    //   'Gali Kasba',
    //   'Healty Dedication',
    //   'Sucessful Faliure',
    //   'Apna Gyaan',
    //   'Yoga',
    //   'Hard Time',
    //   'Part Time',
    //   'Rajneeti fursat wali',
    //   'Bollywood say timepass',
    //   'suspense wale kissay',
    // ];
    // setCategories(allCategories);
    homeCategories();
  }, []);
  return (
    <div className="home-categories my-4">
      {categories?.map((cat, i) => (
        <Link to={`/category/${cat?._id}`} key={i}>
          <span className="letter">
            <FontAwesomeIcon icon={faLetterboxd} size="2x" color="#1da1f2" />
          </span>
          <span>{cat?.name}</span>
        </Link>
      ))}
    </div>
  );
}

export default TopCategories;
