import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faBlogger,
  faXTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import MapEmbed from './MapEmbed';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <Container className="py-3 top-footer">
        <Row>
          <Col md={6}>
            <MapEmbed />
          </Col>
          <Col md={6}>
            <div className="info">
              <div className="name">Junction</div>
              <div className="address">Jaipur.</div>
              <div className="contact">
                Email: lekhjunction@gmail.com <br /> Phone:
              </div>
              <div className="follow">
                <p>Follow us on social media:</p>
                <div className="social-icons">
                  <Link
                    to="https://www.facebook.com/profile.php?id=61560840773253"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faFacebook}
                      size="2x"
                      color="#3b5998"
                    />
                  </Link>
                  <Link
                    to="https://lekhjunction.blogspot.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faBlogger}
                      size="2x"
                      color="#0077b5"
                    />
                  </Link>
                  <Link
                    to="https://x.com/lekhjunction"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faXTwitter}
                      size="2x"
                      color="#1da1f2"
                    />
                  </Link>
                  <Link
                    to="www.youtube.com/@lekhjunction"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faYoutube}
                      size="2x"
                      color="#ff0000"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-center"></Col>
        </Row>
      </Container>
      <div className="bg-dark text-white py-3 text-center">
        <p>Copyright © 2018 Designed by cityjust. All rights reserved.</p>
        <ul>
          <li>
            <Link to="about-us">About Us</Link>
          </li>
          <li>
            <Link to="privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="disclaimer">Disclaimer</Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
