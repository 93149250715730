import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

function Contact() {
  return (
    <div className="page contact">
      <Helmet>
        <title>Lekh - Contact Us</title>
      </Helmet>
      <Container className="my-4">
        <h1>Contact Us</h1>
        <Card>
          <Card.Body>
            <Card.Title>We'd love to hear from you!</Card.Title>
            <Row className="mb-3">
              <Col xs={12} md={4}>
                <h5>Email:</h5>
                <p>lekhjunction@gmail.com</p>
              </Col>
              <Col xs={12} md={4}>
                <h5>Phone:</h5>
                <p>&nbsp;</p> {/* Add your phone number here */}
              </Col>
              <Col xs={12} md={4}>
                <h5>Address:</h5>
                <p>Jaipur, India</p> {/* Add your address here */}
              </Col>
            </Row>
            <Card.Text>
              Thank you for visiting Lekh Junction. We look forward to serving
              you with inspiring and informative content. If you have any query
              or any issue feel free to wite us on email{' '}
              <Link to="mailto:lekhjunction@gmail.com">
                lekhjunction@gmail.com
              </Link>
            </Card.Text>
            <Card.Text>
              Best regards,
              <br />
              Lekh Junction Team
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default Contact;
