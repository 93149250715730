import React from 'react';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

function Privacy() {
  return (
    <div className="about">
      <Helmet>
        <title>Lekh - About Us</title>
      </Helmet>
      <Container className="my-4">
        <h2 class="text-center">Lekhjunction Privacy Policy</h2>

        <h5>Our Commitment</h5>
        <p>Lekhjunction prioritizes user privacy and security.</p>

        <h5>Information Collection</h5>
        <p>Our web servers collect:</p>
        <ul>
          <li>IP address</li>
          <li>Connection data</li>
        </ul>
        <p>for:</p>
        <ul>
          <li>Tailoring content to user interests</li>
          <li>Measuring traffic</li>
          <li>Informing advertisers about user demographics</li>
        </ul>

        <h5>Third-Party Links</h5>
        <p>
          Lekhjunction links to external sites with their own privacy policies,
          beyond our control.
        </p>

        <h5>Advertiser Data</h5>
        <p>
          We share aggregated, anonymous insights on user traffic with
          advertisers.
        </p>

        <h5>Data Security</h5>
        <p>We store data securely:</p>
        <ul>
          <li>Password-protected servers</li>
          <li>Firewall protection</li>
        </ul>
        <p>However, no system is completely secure.</p>

        <h5>Important Notes</h5>
        <ul>
          <li>
            Don't rely on Lekhjunction for personal/medical/legal/financial
            advice; consult professionals.
          </li>
          <li>Data shared in discussion zones is publicly accessible.</li>
        </ul>

        <h5>User-Generated Content</h5>
        <p>Lekhjunction sites feature user-generated content, including:</p>
        <ul>
          <li>Blog posts</li>
          <li>Stories</li>
          <li>Videos</li>
          <li>Analysis</li>
          <li>Reviews</li>
        </ul>
        <p>Lekhjunction does not guarantee:</p>
        <ul>
          <li>Correctness</li>
          <li>Appropriateness</li>
        </ul>

        <h5>No Liability</h5>
        <p>
          You agree not to raise claims or disputes related to user-generated
          content.
        </p>

        <h5>Security and Data Protection</h5>
        <p>Lekhjunction is not responsible for:</p>
        <ul>
          <li>Unauthorized access/altered transmissions/data</li>
          <li>Email account/data maintenance</li>
          <li>Transactions through Lekhjunction properties/content</li>
        </ul>

        <h5>Third-Party Content</h5>
        <p>Lekhjunction is not liable for:</p>
        <ul>
          <li>Threatening/defamatory/obscene/offensive/illegal content</li>
          <li>Infringement of intellectual property rights</li>
          <li>Third-party conduct/content</li>
        </ul>

        <h5>Advertising</h5>
        <p>
          We partner with third-party ad organizations to display relevant
          promotions on our site.
        </p>

        <h5>Data Usage</h5>
        <p>
          These organizations may use data from your visits to deliver targeted
          ads.
        </p>

        <h5>Linked Sites</h5>
        <p>Lekhjunction does not:</p>
        <ul>
          <li>Endorse linked sites</li>
          <li>Affiliate with linked sites</li>
          <li>Control linked site content/updates</li>
        </ul>
        <p>Linked sites are provided for your convenience.</p>

        <h5>Privacy Policy Updates</h5>
        <p>
          We reserve the right to amend/modify our privacy policy at any time.
        </p>

        <h5>Data Protection</h5>
        <p>
          We'll use gathered data consistently with the policy under which it
          was collected.
        </p>

        <h5>Exceptions</h5>
        <p>We may disclose user data, including personal info, when:</p>
        <ul>
          <li>Legally required</li>
          <li>Necessary to protect Lekhjunction's rights</li>
        </ul>

        <h5>User Agreement</h5>
        <p>You agree to:</p>
        <ul>
          <li>Comply with Lekhjunction's terms of service</li>
          <li>Not interfere with security features</li>
        </ul>
      </Container>
    </div>
  );
}

export default Privacy;
