import React from 'react';
import HomeArticles from './LatestArticles';
import TopCategories from './TopCategories';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

function Home() {
  return (
    <div className="homepage">
      <Helmet>
        <title>
          Lekh - Lets do the word dance in your mind one of the finest website
          for all type of news, life experience, knowledge and of course gossip.
        </title>
      </Helmet>
      <div className="home-canvas">
        <img
          src="/images/lekh-junction-header.jpg"
          alt="lekh-junction-header"
        />
      </div>

      <Container className="my-4">
        <TopCategories />
        <h1 className="text-center">Read latest and other stories</h1>
        <HomeArticles />
      </Container>
    </div>
  );
}

export default Home;
