import React from 'react';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

function Disclaimer() {
  return (
    <div className="about">
      <Helmet>
        <title>Lekh - About Us</title>
      </Helmet>
      <Container className="my-4">
        <h2 class="text-center">Disclaimer and Limitation of Liability</h2>

        <p>
          lakhjunction@gmail.com and its suppliers/contributors provide
          information, software, products, services, and graphics "as is"
          without warranty of any kind. We disclaim all express and implied
          warranties, including:
        </p>
        <ul>
          <li>Merchantability</li>
          <li>Fitness for a particular purpose</li>
          <li>Workmanlike effort</li>
          <li>Title</li>
          <li>Non-infringement</li>
        </ul>

        <p>We make no representations about:</p>
        <ul>
          <li>Suitability</li>
          <li>Reliability</li>
          <li>Availability</li>
          <li>Timeliness</li>
          <li>Freedom from viruses/harmful components</li>
          <li>Accuracy</li>
        </ul>

        <h5>Scope</h5>
        <p>
          This disclaimer applies to all Lekhjunction properties, including:
        </p>
        <ul>
          <li>Portals in 8 languages</li>
          <li>Associated applications and services</li>
          <li>SMS service (short code 53434)</li>
          <li>Mobile applications on various operator platforms</li>
          <li>
            All content, including:
            <ul>
              <li>Stories, articles, news, information, interviews, videos</li>
              <li>Puzzles, questions, answers</li>
              <li>Mobile application content</li>
              <li>All allied content on Lekhjunction-associated domains</li>
            </ul>
          </li>
        </ul>

        <h5>Inaccuracies and Changes</h5>
        <p>
          Lekhjunction properties and content may contain inaccuracies,
          typographical errors, or outdated information. We periodically update
          and make changes to our properties and content.
        </p>

        <h5>Disclaimer of Reliance</h5>
        <p>
          Information provided through Lekhjunction should not be relied upon
          for:
        </p>
        <ul>
          <li>Personal advice</li>
          <li>Medical decisions</li>
          <li>Legal counsel</li>
          <li>Financial guidance</li>
        </ul>
        <p>Consult a qualified professional for personalized advice.</p>

        <h5>User-Generated Content</h5>
        <p>
          Some content on Lekhjunction is generated by users through interactive
          processes, including:
        </p>
        <ul>
          <li>Blog posts</li>
          <li>Stories</li>
          <li>Videos</li>
          <li>Analysis</li>
          <li>Reviews</li>
        </ul>
        <p>
          Lekhjunction does not guarantee the accuracy or suitability of this
          content.
        </p>

        <h5>User Agreement</h5>
        <p>Lekhjunction Terms</p>
        <ol>
          <li>Our content may contain errors or outdated information.</li>
          <li>
            Do not rely on Lekhjunction for personal, medical, legal, or
            financial advice. Consult a professional.
          </li>
          <li>
            User-generated content (blogs, stories, videos, etc.) is not
            verified by Lekhjunction.
          </li>
          <li>
            By using Lekhjunction, you acknowledge we are not liable for
            user-generated content.
          </li>
        </ol>

        <h5>Security and Data Protection</h5>
        <p>You acknowledge that Lekhjunction is not responsible for:</p>
        <ul>
          <li>Unauthorized access or alteration of your data/transmissions</li>
          <li>Sending/receiving/saving email data</li>
          <li>Maintaining your email accounts/data</li>
        </ul>

        <h5>Content Liability</h5>
        <p>Lekhjunction is not liable for:</p>
        <ul>
          <li>Threatening, defamatory, obscene, or illegal content</li>
          <li>Infringement of intellectual property rights</li>
          <li>Third-party content or conduct</li>
          <li>Content sent using Lekhjunction properties</li>
        </ul>

        <h5>Third-Party Links</h5>
        <p>
          This site may contain links to external websites ("Linked Sites"). You
          understand:
        </p>
        <ul>
          <li>Lekhjunction does not endorse or affiliate with Linked Sites</li>
          <li>
            Lekhjunction is not responsible for Linked Site content/updates
          </li>
          <li>Linked Sites may contain Lekhjunction branding/advertisements</li>
          <li>Lekhjunction provides links solely as a convenience</li>
        </ul>

        <h5>Ownership and Rights</h5>
        <p>
          The content, service marks, logos, designs, graphics, and ideas on
          (link unavailable) are owned or licensed exclusively to Lekhjunction.
        </p>

        <h5>Prohibited Actions</h5>
        <p>You are strictly prohibited from:</p>
        <ul>
          <li>
            Unauthorized copying, publication, or distribution of website
            content
          </li>
          <li>
            Reproducing or manipulating videos for any purpose beyond viewing
          </li>
          <li>Providing false or outdated personal information</li>
        </ul>

        <h5>Accountability</h5>
        <p>
          You agree to provide accurate and updated personal information when
          using Lekhjunction services or applications. Failure to do so
          constitutes illegal use.
        </p>
        <p>Lekhjunction shall not be responsible or liable for:</p>
        <ul>
          <li>
            Temporary website unavailability due to technical issues beyond our
            control
          </li>
        </ul>

        <h5>Limitation of Liability</h5>
        <p>
          Lekhjunction and its suppliers/contributors shall not be liable for:
        </p>
        <ul>
          <li>
            Direct, indirect, punitive, incidental, special, or consequential
            damages
          </li>
          <li>
            Loss of use, data, profits, or any damages related to Lekhjunction
            properties/content
          </li>
          <li>Delay/inability to use services or products</li>
          <li>
            Information, software, products, services, or graphics obtained
            through Lekhjunction
          </li>
        </ul>

        <h5>No Endorsement</h5>
        <p>
          Lekhjunction does not endorse advertisers or their content. Verify
          information before reliance/action.
        </p>

        <h5>Consequential Damages</h5>
        <p>
          Lekhjunction shall not be responsible for consequential damages
          arising from reliance on advertisement content.
        </p>

        <h5>Governing Law</h5>
        <p>
          This agreement is governed by Indian laws. You consent to exclusive
          jurisdiction/venue of Indore, M.P., India courts.
        </p>

        <h5>Indemnification</h5>
        <p>
          You agree to indemnify Lekhjunction, subsidiaries, affiliates,
          officers, employees, and hold harmless from:
        </p>
        <ul>
          <li>Claims, demands, damages (including attorneys' fees)</li>
          <li>
            Arising from your use/conduct on Lekhjunction properties/content
          </li>
        </ul>

        <h5>Disclosure of Personal Information</h5>
        <p>
          Lekhjunction reserves the right to disclose your personal information,
          including:
        </p>
        <ul>
          <li>Nickname</li>
          <li>Name</li>
          <li>Photograph</li>
          <li>Voice/video recordings</li>
        </ul>
        <p>Without prior consent, if:</p>
        <ol>
          <li>Required by law/legal process</li>
          <li>Necessary to protect Lekhjunction's rights/property</li>
          <li>Enforcing terms of use</li>
          <li>Protecting members' or others' interests</li>
        </ol>

        <h5>Use of Information for Promotional Purposes</h5>
        <p>Lekhjunction may use your information for:</p>
        <ul>
          <li>Publicity</li>
          <li>Advertising</li>
          <li>Trade/promotion</li>
        </ul>
        <p>in any media.</p>

        <h5>Governing Law</h5>
        <p>
          Lekhjunction's performance is subject to existing laws and legal
          processes.
        </p>

        <h5>Compliance with Authorities</h5>
        <p>
          Lekhjunction reserves the right to comply with governmental, court,
          and law enforcement requests relating to your use of Lekhjunction
          properties and content.
        </p>

        <h5>Invalid Provisions</h5>
        <p>
          If any part of this agreement is invalid or unenforceable, it will be
          replaced with a valid provision that closely matches the original
          intent.
        </p>

        <h5>Entire Agreement</h5>
        <p>
          This agreement constitutes the entire understanding between you and
          Lekhjunction regarding Lekhjunction properties and content.
        </p>

        <h5>Additional Terms</h5>
        <p>You agree to:</p>
        <ul>
          <li>Comply with Lekhjunction's terms of service</li>
          <li>
            Not interfere with security features of Lekhjunction properties
          </li>
        </ul>
      </Container>
    </div>
  );
}

export default Disclaimer;
