import React, { useEffect } from 'react';
import { Row, Col, Card, Button, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { getError } from '../../Utils';
import ArticleExcerpt from './ArticleExcerpt';

function CategoryDetail() {
  const { id } = useParams();
  const appBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [category, setCategory] = useState([]);
  const [articles, setArticles] = useState([]);
  const [limit, setLimit] = useState(10); // State for limit

  const fetchCategory = async (id) => {
    try {
      const { data } = await Axios.get(`${appBaseUrl}/api/categories/${id}`);
      setCategory(data);
    } catch (err) {
      toast.error(getError(err));
    }
  };

  const fetchArticles = async (id) => {
    try {
      const articleResults = await Axios.get(`${appBaseUrl}/api/articles/`, {
        params: { categoryId: id, limit },
      });

      setArticles(articleResults?.data);
    } catch (err) {
      toast.error(`Error: ${err.message}`);
    }
  };

  useEffect(() => {
    fetchCategory(id);
    fetchArticles(id);
  }, [id]);

  return (
    <Container>
      <div className="home articles my-4">
        <h1>{category?.name}</h1>
        <p>{category?.description}</p>
        <Row className="my-4 articles g-3">
          {articles?.map((article, i) => (
            <Col md={6} lg={4} key={i}>
              <Card>
                <div className="image-container">
                  <Card.Img
                    variant="top"
                    src={article?.imageUrl}
                    alt={article.name}
                  />
                </div>
                <Card.Body>
                  <Card.Title>{article?.name}</Card.Title>
                  <ArticleExcerpt description={article?.description} />
                  <Link className="read-more" to={`/articles/${article._id}`}>
                    <Button variant="primary">Read More</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  );
}

export default CategoryDetail;
